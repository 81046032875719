// @ts-nocheck
/* eslint-disable */

'use client';

import { Button, Column, Flex, FormField, Heading, Icon, RichText, Row, variables } from 'daskapital-ds';
import React, { useEffect, useRef, useState } from 'react';
import {
	StyledBubble,
	calculateLoanAmountInstalment,
	calculateLoanAmountReceived,
	calculateLoanTotalPayment,
	formatMonthsToHuman,
	formsAttrs,
	validateStringData,
} from './simulator.utils';

import Link from 'next/link';
import { NumericFormat } from 'react-number-format';
import { useGlobalContext } from '@/lib/globalContext';

export default function FundProjects({ getStarted, knowMore }: formsAttrs) {
	const state = useGlobalContext();
	const maturityDays = [6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120];

	const defaultInstallments = [
		{
			value: 'MONTHLY',
			label: state?.dict?.custom?.monthly,
			include: [6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
			divider: 1,
		},
		{
			value: 'BIMONTHLY',
			label: state?.dict?.custom?.bimonthly,
			include: [6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
			divider: 2,
		},
		{
			value: 'QUARTERLY',
			label: state?.dict?.custom?.quarterly,
			include: [6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
			divider: 3,
		},
		{
			value: 'SEMIYEARLY',
			label: state?.dict?.custom?.semiyearly,
			include: [6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
			divider: 6,
		},
		{
			value: 'ANNUALLY',
			label: state?.dict?.custom?.annually,
			include: [12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
			divider: 12,
		},
	];

	const [message, setMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [fields, setFields] = useState<Record<string, string | number>>({
		maturityDays: maturityDays[0],
		interestRate: 4,
		installment: 'MONTHLY',
	});
	const [total, setTotal] = useState<number>(0);
	const [upfront, setUpfront] = useState<number>(0);
	const [amountInstalment, setAmountInstalment] = useState<number>(0);
	const [installmentOptions, setInstallmentOptions] = useState(defaultInstallments);

	useEffect(() => {
		const newInstallments = defaultInstallments;
		const filteredInstallments = newInstallments.filter((e) => e.include.indexOf(fields.maturityDays) < 0);
		filteredInstallments.forEach((f) =>
			newInstallments.splice(
				newInstallments.findIndex((e) => e.value === f.value),
				1
			)
		);

		//change installment value state if not in array
		if (newInstallments.findIndex((e) => e.value === fields.installment) < 0) {
			setFields({ ...fields, installment: 'MONTHLY' });
		}
		setInstallmentOptions(newInstallments);
	}, [fields.maturityDays]);

	const formatter = new Intl.NumberFormat(`fr-FR`, {
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const setValue = (key, value) => {
		setFields({ ...fields, [key]: value });
		// calcTotal();
	};

	const calcTotal = () => {
		if (!fields.amount || !fields.maturityDays || !fields.interestRate || !fields.installment) return;

		const params = {
			instalment_freq: fields.installment,
			amount_requested: fields.amount,
			term_months: fields.maturityDays,
			max_apr: fields.interestRate,
		};

		setAmountInstalment(formatter.format(calculateLoanAmountInstalment(params)));
		setUpfront(formatter.format(calculateLoanAmountReceived(fields.amount, fields.maturityDays)));
		setTotal(formatter.format(calculateLoanTotalPayment(params)));
	};

	useEffect(() => {
		calcTotal();
	}, [fields]);

	useEffect(() => {
		document.querySelectorAll('input[type="number"]').forEach((item) => {
			item.dispatchEvent(new Event('change'));
		});

		calcTotal();
	}, []);

	return (
		<>
			<div className="block-group radius-md" style={{ overflow: 'hidden' }}>
				<Row fluid={true} className="m-0">
					<Column lg={8}>
						<div className="mb-8 mb-md-0 pe-md-8">
							<Heading size={'h3'} tag={'h4'}>
								{state?.dict?.custom?.fundProject}
							</Heading>
							<Heading size={'texts'} tag={'span'} className="font-alternative mt-3">
								{state?.dict?.custom?.fundProjectTxt}
							</Heading>
							<div className="mt-7">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.amountTitleFund}
								</Heading>
								<div className="mt-2">
									<FormField id="amount" className="mb-0">
										<NumericFormat
											thousandSeparator=" "
											decimalSeparator=","
											suffix={'€'}
											decimalScale={2}
											allowNegative={false}
											name="amount"
											placeholder={state?.dict?.custom?.amount}
											className="font-alternative w-100"
											onChange={(e) => {
												setValue('amount', parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.')));
											}}
										/>
									</FormField>
								</div>
							</div>
							<div className="mt-6">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.maturityDaysProjects}
								</Heading>
								<div className="d-flex align-items-center mt-2" style={{ gap: '1rem' }}>
									<div className="flex-grow-1 pt-1">
										<FormField id="maturityDays" className="mb-0 align-items-center">
											<input
												type={'range'}
												name="maturityDays"
												className="w-100"
												rangeValues={maturityDays}
												max={maturityDays.length - 1}
												defaultValue={0}
												onChange={(e) => {
													e.preventDefault();
													setValue('maturityDays', maturityDays[e.target.value]);
												}}
											/>
										</FormField>
									</div>
									<div className="flex-shrink-1">
										<StyledBubble type="text" className="texts">
											{fields.maturityDays}
										</StyledBubble>
									</div>
								</div>
							</div>

							<div className="mt-6">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.installment}
								</Heading>
								<div className="d-flex align-items-center mt-2" style={{ gap: '1rem' }}>
									<div className="flex-grow-1">
										<FormField id="installment" className="mb-0">
											<select
												name="installment"
												className="w-100"
												onChange={(e) => {
													setValue('installment', e.target.value);
												}}>
												{installmentOptions.map((install, index) => {
													return (
														<option key={`installment-${index}`} value={install.value}>
															{install.label}
														</option>
													);
												})}
											</select>
										</FormField>
									</div>
								</div>
							</div>

							<div className="mt-6">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.interestRateMax}
								</Heading>
								<div className="d-flex align-items-center mt-2" style={{ gap: '1rem' }}>
									<div className="flex-grow-1 pt-1">
										<FormField id="interestRate" className="mb-0">
											<input
												type={'range'}
												name="interestRate"
												className="w-100"
												min={4}
												max={20}
												step={0.1}
												defaultValue={4}
												onChange={(e) => {
													setValue('interestRate', e.target.value);
												}}
											/>
										</FormField>
									</div>
									<div className="flex-shrink-1">
										<StyledBubble type="text" className="texts">
											{fields.interestRate}%
										</StyledBubble>
									</div>
								</div>
							</div>
						</div>
					</Column>
					<Column lg={4} className="bg-gray70 color-white radius-sm">
						<div className="d-flex justify-content-between p-5 h-100 simulator-totals" style={{ flexFlow: 'column' }}>
							<div>
								<Heading size={'texts'} tag={'h4'} className="font-alternative mb-4">
									{state?.dict?.custom?.payForDays
										?.replaceAll('{{months}}', formatMonthsToHuman(fields.maturityDays))
										.replaceAll('{{installment}}', validateStringData(fields.installment?.toLowerCase(), 0))}
								</Heading>

								<Heading size={'h2'} tag={'span'} className="color-secondary50 mb-4">
									{`${amountInstalment}€`}
								</Heading>

								<Heading size={'texts'} tag={'span'} className="font-alternative mb-4">
									{state?.dict?.custom?.fundedIn?.replaceAll('{{value}}', upfront)}
								</Heading>

								<Heading size={'texts'} tag={'span'} className="font-alternative mb-4">
									{state?.dict?.custom?.totalPayment?.replaceAll('{{value}}', total)}
								</Heading>
							</div>
							<div className="text-center mt-6">
								<Link href={getStarted?.url ? getStarted?.url : '/contacts'} target={getStarted?.target} className="d-block">
									<Button variant="secondary" className="justify-content-center w-100">
										{state.dict.custom.getStarted}
									</Button>
								</Link>
								<Link href={knowMore?.url ? knowMore?.url : '/contacts'} target={knowMore?.target} className="d-inline-block">
									<Button variant="link" className="color-white">
										{state.dict.common.knowMore}
									</Button>
								</Link>
							</div>

							{message && (
								<RichText tag="p" className="color-white mt-5">
									{message}
								</RichText>
							)}
						</div>
					</Column>
				</Row>
			</div>
		</>
	);
}
