// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Button,
  Column,
  Flex,
  FormField,
  Heading,
  Icon,
  RichText,
  Row,
  Separator,
  variables,
} from "daskapital-ds";
import React, { useEffect, useRef, useState } from "react";
import { StyledBubble, calculateInvoiceAmountInvested, calculateInvoiceAmountReceived, calculateInvoiceAmountReceivedNow, formsAttrs } from "./simulator.utils";

import Link from "next/link";
import { NumericFormat } from "react-number-format";
import ReactDOMServer from "react-dom/server";
import { useGlobalContext } from "@/lib/globalContext";

export default function InvestInvoices({ getStarted, knowMore }: formsAttrs) {
  const state = useGlobalContext();
  const maturityDays = [30, 45, 60, 90, 120, 150, 180]

  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<Record<string, string | number>>({
    amount: 0,
    maturityDays: maturityDays[0],
    interestRate: 4,
  });
  const [chosenDays, setChosenDays] = useState<number>(maturityDays[0]);
  const [total, setTotal] = useState<number>(0);
  const [upfront, setUpfront] = useState<number>(0);
  const [paidDays, setPaidDays] = useState<number>(0);

  const formatter = new Intl.NumberFormat(`fr-FR`, {
		currency: "EUR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

  const setValue = (key, value) => {
    setFields({ ...fields, [key]: value });
    // calcTotal();
  };


  const calcTotal = () => {
    if (
      !fields.amount ||
      !fields.maturityDays ||
      !fields.interestRate
    )
      return;


    const paidUpfront = fields.amount * (fields.interestRate * (fields.maturityDays / 365 / 100))
    const total = fields.amount + paidUpfront;

    setTotal(formatter.format(total));
    setUpfront(formatter.format(paidUpfront));
    setPaidDays(formatter.format(fields.amount));
  };

  useEffect(() => {
    calcTotal();
  }, [fields]);


  useEffect(() => {
    document.querySelectorAll('input[type="number"]').forEach((item) => {
      item.dispatchEvent(new Event('change'));
    })

    calcTotal();
  }, []);

  const linkTerms = ReactDOMServer.renderToStaticMarkup(
    <Link
      href={`/${state.lang}/contact-us/`}
      className="color-primary50"
    >
      {state?.dict?.common?.knowMore}
    </Link>
  );

  return (
    <>
      <div
        className="block-group radius-md"
        style={{ overflow: "hidden" }}
      >
        <Row fluid={true} className="m-0">
          <Column lg={8}>
            <div className="mb-8 mb-md-0 pe-md-8">
              <Heading size={"h3"} tag={"h4"}>
                {state?.dict?.custom?.investInvoices}
              </Heading>
              <Heading size={"texts"} tag={"span"} className="font-alternative mt-3">
                {state?.dict?.custom?.investInvoicesTxt}
              </Heading>
              <div className="mt-7">
                <Heading size={"texts"} tag={"span"} className="font-alternative">
                  {state?.dict?.custom?.amountTitle}
                </Heading>
                <div className="mt-2">
                  <FormField
                    id="amount"
                    className="mb-0"
                  >
                    <NumericFormat
                      thousandSeparator=" "
                      decimalSeparator=","
                      suffix={'€'}
                      decimalScale={2}
                      allowNegative={false}
                      name="amount"
                      placeholder={state?.dict?.custom?.amount}
                      className="font-alternative w-100"
                      onChange={(e) => {
                        setValue("amount", parseFloat(e.target.value.replaceAll(" ", "").replaceAll(",", ".")));
                      }}
                    />
                  </FormField>
                </div>
              </div>
              <div className="mt-6">
                <Heading size={"texts"} tag={"span"} className="font-alternative">
                  {state?.dict?.custom?.maturityDays}
                </Heading>
                <div className="d-flex align-items-center mt-2" style={{ gap: "1rem" }}>
                  <div className="flex-grow-1">
                    <FormField
                      id="maturityDays"
                      className="mb-0"
                    >
                      <input
                        type={"range"}
                        name="maturityDays"
                        className="w-100"
                        rangeValues={maturityDays}
                        max={maturityDays.length - 1}
                        defaultValue={0}
                        onChange={(e) => {
                          e.preventDefault();
                          setValue("maturityDays", maturityDays[e.target.value]);
                          setChosenDays(maturityDays[e.target.value]);
                        }}
                      />
                    </FormField>
                  </div>
                  <div className="flex-shrink-1">
                    <StyledBubble type="text" className="texts">{fields.maturityDays}</StyledBubble>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <Heading size={"texts"} tag={"span"} className="font-alternative">
                  {state?.dict?.custom?.interestRate}
                </Heading>
                <div className="d-flex align-items-center mt-2" style={{ gap: "1rem" }}>
                  <div className="flex-grow-1">
                    <FormField
                      id="interestRate"
                      className="mb-0"
                    >
                      <input
                        type={"range"}
                        name="interestRate"
                        className="w-100"
                        min={4}
                        max={20}
                        step={0.1}
                        defaultValue={4}
                        onChange={(e) => {
                          setValue("interestRate", e.target.value);
                        }}
                      />
                    </FormField>
                  </div>
                  <div className="flex-shrink-1">
                    <StyledBubble type="text" className="texts">{fields.interestRate}%</StyledBubble>
                  </div>
                </div>
              </div>


              <div className="d-flex bg-gray10 radius-sm p-4 mt-9">
                <Icon icon="icon-info" className="color-primary50 mt-1 me-2" />
                <span className="d-block">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: state?.dict?.custom?.terms?.replaceAll(
                        "{{link}}",
                        linkTerms
                      ),
                    }}
                  ></p>
                </span>
              </div>

            </div>
          </Column>
          <Column
            lg={4}
            className="bg-gray10 color-gray90 radius-sm"
          >
            <div className="d-flex justify-content-between p-5 h-100 simulator-totals" style={{ flexFlow: "column" }}>
              <div>
                <Heading size={"texts"} tag={"h4"} className="font-alternative mb-4">
                  {state?.dict?.custom?.valueInDays?.replaceAll("{{value}}", chosenDays)}
                </Heading>

                <Heading size={"h2"} tag={"span"} className="color-primary50 mb-4">
                  {`${total}€`}
                </Heading>

                <Heading size={"texts"} tag={"span"} className="font-alternative mb-4">
                  {state?.dict?.custom?.interestUpfront?.replaceAll("{{value}}", upfront)}
                </Heading>

                <Heading size={"texts"} tag={"span"} className="font-alternative mb-4">
                  {state?.dict?.custom?.interestInDays?.replaceAll("{{value}}", paidDays).replaceAll("{{days}}", chosenDays)}
                </Heading>

                <Separator color="var(--color-gray40)" height="1px" />
              </div>
              <div className="text-center mt-6">
                <Link href={getStarted?.url ? getStarted?.url : "/contacts"} target={getStarted?.target} className="d-block">
                  <Button variant="primary" className="justify-content-center w-100">{state.dict.custom.getStarted}</Button>
                </Link>
                <Link href={knowMore?.url ? knowMore?.url : "/contacts"} target={knowMore?.target} className="d-inline-block">
                  <Button variant="link">{state.dict.common.knowMore}</Button>
                </Link>
              </div>
              {message && (
                <RichText tag="p" className="color-white mt-5">
                  {message}
                </RichText>
              )}
            </div>
          </Column>
        </Row>
      </div>

    </>
  );
}
