import { IPost } from '@/lib/wordpress/_types/archiveGrid';
import Link from 'next/link';
import styled from 'styled-components';

const StyledFaqCard = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	height: 60px;
`;

/**
 *
 * @param {Post} {post}
 * @returns {JSX.Element} // A link containing information about a FAQ
 */
export const FaqCard = ({ post }: { post: IPost }) => {
	return (
		<StyledFaqCard href={post.slug as string} aria-label={`Read more about ${post.title}`}>
			<span>{post.title}</span>
			<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.8107 0L0.1875 0L0.1875 3.22479L7.59457 3.22479L0.1875 9.8357L0.1875 14L7.99847 7.01344L15.8103 14V9.8357L8.40362 3.22479L15.8107 3.22479V0Z"
					fill="#000000"
				/>
			</svg>
		</StyledFaqCard>
	);
};
