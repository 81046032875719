// @ts-nocheck
/* eslint-disable */

'use client';

import { Button, Column, Flex, FormField, Heading, Icon, RichText, Row, Separator, variables } from 'daskapital-ds';
import React, { useEffect, useRef, useState } from 'react';
import { StyledBubble, calculateInvoiceAmountReceived, calculateInvoiceAmountReceivedNow } from './simulator.utils';

import Link from 'next/link';
import { NumericFormat } from 'react-number-format';
import { useGlobalContext } from '@/lib/globalContext';

export default function SellInvoices({ getStarted, knowMore }: formsAttrs) {
	const state = useGlobalContext();
	const maturityDays = [30, 45, 60, 90, 120, 150, 180];
	const [message, setMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [fields, setFields] = useState<Record<string, string | number>>({
		maturityDays: maturityDays[0],
		discount: 0.5,
	});
	const [total, setTotal] = useState<number>(0);
	const [upfront, setUpfront] = useState<number>(0);

	const setValue = (key, value) => {
		setFields({ ...fields, [key]: value });
	};

	const formatter = new Intl.NumberFormat(`fr-FR`, {
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const calcTotal = () => {
		if (!fields.amount || !fields.maturityDays || !fields.discount) return;

		const total = calculateInvoiceAmountReceived(fields.amount);
		const paidUpfront = calculateInvoiceAmountReceivedNow({
			gross_total: fields.amount,
			invoice_maturity: fields.maturityDays,
			max_discount: fields.discount,
		});

		setTotal(formatter.format(total));
		setUpfront(formatter.format(paidUpfront));
	};

	useEffect(() => {
		calcTotal();
	}, [fields]);

	useEffect(() => {
		document.querySelectorAll('input[type="number"]').forEach((item) => {
			item.dispatchEvent(new Event('change'));
		});

		calcTotal();
	}, []);

	return (
		<>
			<div className="block-group radius-md" style={{ overflow: 'hidden' }}>
				<Row fluid={true} className="m-0">
					<Column lg={8}>
						<div className="mb-8 mb-md-0 pe-md-8">
							<Heading size={'h3'} tag={'h4'}>
								{state?.dict?.custom?.sellInvoices}
							</Heading>
							<Heading size={'texts'} tag={'span'} className="font-alternative mt-3">
								{state?.dict?.custom?.sellInvoicesTxt}
							</Heading>
							<div className="mt-7">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.amountTitleSell}
								</Heading>
								<div className="mt-2">
									<FormField id="amount" className="mb-0">
										<NumericFormat
											thousandSeparator=" "
											decimalSeparator=","
											suffix={'€'}
											decimalScale={2}
											allowNegative={false}
											name="amount"
											placeholder={state?.dict?.custom?.amount}
											className="font-alternative w-100"
											onChange={(e) => {
												setValue('amount', parseFloat(e.target.value.replaceAll(' ', '').replaceAll(',', '.')));
											}}
										/>
									</FormField>
								</div>
							</div>
							<div className="mt-6">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.maturityDays}
								</Heading>
								<div className="d-flex align-items-center mt-2" style={{ gap: '1rem' }}>
									<div className="flex-grow-1 pt-1">
										<FormField id="maturityDays" className="mb-0">
											<input
												type={'range'}
												name="maturityDays"
												className="w-100"
												rangeValues={maturityDays}
												max={maturityDays.length - 1}
												defaultValue={0}
												onChange={(e) => {
													e.preventDefault();
													setValue('maturityDays', maturityDays[e.target.value]);
												}}
											/>
										</FormField>
									</div>
									<div className="flex-shrink-1">
										<StyledBubble type="text" className="texts">
											{fields.maturityDays}
										</StyledBubble>
									</div>
								</div>
							</div>

							<div className="mt-6">
								<Heading size={'texts'} tag={'span'} className="font-alternative">
									{state?.dict?.custom?.discount}
								</Heading>
								<div className="d-flex align-items-center mt-2" style={{ gap: '1rem' }}>
									<div className="flex-grow-1 pt-1">
										<FormField id="discount" className="mb-0">
											<input
												type={'range'}
												name="discount"
												className="w-100"
												min={0.5}
												max={10}
												step={0.5}
												defaultValue={0.5}
												onChange={(e) => {
													setValue('discount', e.target.value);
												}}
											/>
										</FormField>
									</div>
									<div className="flex-shrink-1">
										<StyledBubble type="text" className="texts">
											{fields.discount}%
										</StyledBubble>
									</div>
								</div>
							</div>
						</div>
					</Column>
					<Column lg={4} className="bg-gray70 radius-sm">
						<div className="d-flex justify-content-between p-5 h-100 simulator-totals" style={{ flexFlow: 'column' }}>
							<div className="color-white">
								<Heading size={'texts'} tag={'h4'} className="font-alternative mb-4">
									{state?.dict?.custom?.amountReceiveNow}
								</Heading>

								<Heading size={'h2'} tag={'span'} className="color-secondary50 mb-4">
									{`${upfront}€`}
								</Heading>

								<Separator color="var(--color-white)" height="1px" className="mb-5" />

								<Heading size={'texts'} tag={'span'} className="font-alternative mb-4">
									{state?.dict?.custom?.amountReceiveAfter}
								</Heading>
								<Heading size={'h2'} tag={'span'} className="color-secondary50 mb-4">
									{`${total}€`}
								</Heading>
							</div>
							<div className="text-center mt-6">
								<Link href={getStarted?.url ? getStarted?.url : '/contacts'} target={getStarted?.target} className="d-block">
									<Button variant="secondary" className="justify-content-center w-100">
										{state.dict.custom.getStarted}
									</Button>
								</Link>
								<Link href={knowMore?.url ? knowMore?.url : '/contacts'} target={knowMore?.target} className="d-inline-block">
									<Button variant="link" className="color-white">
										{state.dict.common.knowMore}
									</Button>
								</Link>
							</div>
							{message && (
								<RichText tag="p" className="color-white mt-5">
									{message}
								</RichText>
							)}
						</div>
					</Column>
				</Row>
			</div>
		</>
	);
}
