import { WpRequest } from '../wordpress/wpRequest';

async function getMenus(language = 'en') {
	const auth =
		'Basic ' +
		btoa(`${process.env.NEXT_PUBLIC_WORDPRESS_APPLICATION_USERNAME}` + ':' + `${process.env.NEXT_PUBLIC_WORDPRESS_APPLICATION_PASSWORD}`);
	const menus = await fetch(`${process.env.NEXT_PUBLIC_WP_URL}wp-json/miew/menus?&lang=${language}`, {
		headers: {
			Authorization: auth,
			'Content-Type': 'application/json',
		},
	})
		.then((response) => response.json())
		.then((result) => result)
		.catch((error) => console.error(error));

	// https://daskapital-website-cms.miewenabler.com/wp-json/miew/content/?post_type=wp_block&slug=13&lang=es
	const footer = await fetch(`${process.env.NEXT_PUBLIC_WP_URL}wp-json/miew/content?post_type=wp_block&slug=13&lang=?&lang=${language}`, {
		headers: {
			Authorization: auth,
			'Content-Type': 'application/json',
		},
	})
		.then((response) => response.json())
		.then((result) => result)
		.catch((error) => console.error(error));

	return { menus, footer };
}

export default getMenus;
