'use client';

import { Column, EmptyState, Flex, Heading, HelpCenterCard, Loader, Row } from 'daskapital-ds';
import { IArchiveGrid, IPost, Taxonomy } from '@/lib/wordpress/_types/archiveGrid';
import React, { Suspense, useEffect, useState } from 'react';

import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import Link from 'next/link';
import { WpClientRequest } from '@/lib/wordpress/wpClientRequest';
import { formatDate } from '@/lib/helpers/dateFormate';
import { useGlobalContext } from '@/lib/globalContext';

export default function BlockTaxonomyGrid({ attrs }: IArchiveGrid) {
	const { title, variant, taxonomy, component, per_line = 4, per_page, grid, className } = attrs || {};

	const colSize = 12 / per_line;
	const [loading, setLoading] = useState<boolean | null>(false);
	const [filteredPosts, setFilteredPosts] = useState(grid?.items || (grid as unknown as []));
	const state = useGlobalContext();
	const { lang } = state;

	useEffect(() => {
		// if (grid) {
		//   setFilteredPosts(grid);
		// }
		// if (taxonomy) {
		//   setLoading(true);
		//   WpClientRequest({
		//     taxonomy,
		//     variables: {
		//       limit: per_page,
		//       lang,
		//     },
		//   }).then((res) => {
		//     setFilteredPosts(res);
		//     setLoading(false);
		//   });
		// }
	}, [taxonomy, lang, grid]);

	return (
		<Row fluid={true} className={className}>
			<Column>
				<Flex align="center">
					{title && (
						<div className="flex-shrink-1 mb-3">
							<Heading tag="span" size="h4" className="fw-semibold">
								{title}
							</Heading>
						</div>
					)}
				</Flex>
			</Column>
			{loading ? (
				<Column className="text-center pt-5 pb-5">
					<Loader label={state?.dict?.common?.loading}></Loader>
				</Column>
			) : (
				<Suspense fallback={<Loader label={state?.dict?.common?.loading}></Loader>}>
					{filteredPosts && filteredPosts.length !== 0 ? (
						filteredPosts.map((post: IPost, i: number) => {
							if (!post) return false;

							const category = (post?.categories as unknown as Taxonomy[]) || [];
							const categoryName = category && Object.keys(category).length > 0 ? category[0]?.items[0]?.name : '';

							const imageElement = (
								<DisplayImage url={post?.acf?.image as string} alt={post?.name ? (post?.name as string) : ''} fill={true} />
							);

							switch (component) {
								case 'HelpCenterCard':
									return (
										<Column key={`HelpCenterCard-${i}`} xs={12} sm={6} lg={colSize} className="mb-4">
											<HelpCenterCard
												imageElement={imageElement}
												title={post.name}
												description={post.description}
												className="h-100"
												linkElement={
													<Link
														href={`/help-center/category/${post.slug as string}`}
														aria-label={`Read more about ${post.title}`}
														role="link"
														className="linker"></Link>
												}
											/>
										</Column>
									);
							}
						})
					) : (
						<Column className="pt-7 pb-5">
							<EmptyState label={state?.dict?.common?.noResults}></EmptyState>
						</Column>
					)}
				</Suspense>
			)}
		</Row>
	);
}
