//@ts-nocheck
'use client';

import React, { Fragment, useMemo } from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { Heading } from 'daskapital-ds';
import classNames from 'classnames';
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles';

export type IBlockHeading = {
	attrs: {
		anchor?: string;
		content?: string;
		level?: number;
		fontSize?: string;
		className?: string;
		style?: Record<string, unknown>;
		animation?: string;
	};
};

export default function BlockHeading({ attrs }: IBlockHeading) {
	const { anchor, content, level, fontSize, className, style, animation } = attrs || {};

	const headingStyle = useMemo(
		() => ({
			...getBlockStyles({
				style,
			}),
		}),
		[style]
	);

	//bail out
	if (!content) return <></>;

	const sanitizedContent: string = DOMPurify.sanitize(content, {
		ADD_ATTR: ['target', 'style', 'class', 'type', 'href', 'rel'],
		ALLOWED_TAGS: ['link', 'mark', 'kbd', 'b', 'strong', 'em', 'i'],
	});

	return (
		<Heading
			id={anchor || undefined}
			tag={level ? `h${level}` : 'h3'}
			// size={fontSize ? fontSize : level ? `h${level}` : "h3"}
			className={classNames(className, animation && `elements_animated ${animation}`)}
			style={{
				...headingStyle,
			}}>
			{sanitizedContent}
		</Heading>
	);
}
