'use client';

import { BidAuctionCard, Column, Slider, SpecialBidAuctionCard } from 'daskapital-ds';
import React, { useCallback, useEffect, useState } from 'react';

import Link from 'next/link';
import { useGlobalContext } from '@/lib/globalContext';
import { useRouter, useParams } from 'next/navigation';
import Image from 'next/image';

interface IAttrs {
	className?: string;
	style?: Record<string, unknown>;
	slidesToShow?: number;
	slidesToShowMobile?: number;
	spacing?: string;
	dots?: boolean;
	arrows?: boolean;
	isFeatured?: boolean;
	limit?: number;
	offset?: number;
}

interface IBlockAuctionCards {
	attrs: IAttrs;
}

const BlockBidAuctionCard: React.FC<IBlockAuctionCards> = ({ attrs }) => {
	const [auctions, setAuctions] = useState<any[]>([]);
	const params = useParams();

	const state = useGlobalContext();

	const {
		slidesToShow = 3,
		slidesToShowMobile = 1,
		spacing = '1rem',
		arrows = true,
		dots = true,
		limit = 12,
		offset = 0,
		isFeatured = false,
	} = attrs;

	//todo: passar para formatBlockData
	useEffect(() => {
		const fetchData = async () => {
			try {
				const newLimit = limit ? `&limit=${limit}` : '';
				const newOffset = offset ? `&offset=${offset}` : '';
				const newIsfeatured = isFeatured ? `&featured=${isFeatured}` : '';

				/**
				 * Type of auction changes according to params;
				 */
				const auctionTypes: { [k: string]: string } = {
					'dasfactoring-investors': '&auction_type[]=assets',
					'daslending-investors': '&auction_type[]=invoice',
				};

				/**
				 * If auctionTypes return undefined it should fetch all auctions
				 * as default.
				 * @return typeof auctions params | ''
				 */
				const auctionType = auctionTypes[params.slug as string] || '';

				const url = `${process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM_API}${newLimit}${newOffset}${newIsfeatured}${auctionType}`;
				const response = await fetch(url);
				const data = await response.json();
				setAuctions(data.results ?? []);
			} catch (e) {
				console.log(e);
			}
		};

		fetchData();
	}, []);

	if (!auctions || auctions.length === 0) {
		return null;
	}

	const auctionImage = (auction: any) => {
		let img;

		if (auction.auction_type === 'assets') {
			img = auction.images?.flatMap((image: any) => image.files?.find((f: any) => f.cover)?.download_url)[0];
		} else {
			img = auction.client?.logo_url;
		}
		return img || '/assets/placeholder.png';
	};

	return (
		<Slider
			slidesToShow={slidesToShow}
			slidesToScroll={slidesToShow}
			dots={dots}
			infinite={false}
			arrows={arrows}
			mediaQueries={{
				1600: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
				1200: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
				680: {
					arrows: false,
					slidesToShow: slidesToShowMobile,
					slidesToScroll: slidesToShowMobile,
				},
			}}
			spacing={spacing}>
			{auctions.map((auction) => (
				<div key={auction.id} className="p-2">
					<Link href={`${process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM}/${auction.id}`} key={auction.id} className="p-2">
						<SpecialBidAuctionCard
							imageElement={<Image src={auctionImage(auction)} fill alt="s" />}
							auction={auction}></SpecialBidAuctionCard>
					</Link>
				</div>
			))}
		</Slider>
	);
};

export default BlockBidAuctionCard;
